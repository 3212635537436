
.notification-modal {
    position: fixed;
    top: 9vw;
    width: 30vw;
    height: 6vw;
    padding: 0 2vw;
    right: 33vw;
    background: #515151;
    border-radius: 0.6vw;
    font-size: 1.2vw;
    cursor: pointer;
    transition: top 0.3s;
    color: #fff;
    display: grid;
    align-items: center;
    grid-template-columns: 9% 91%;
    column-gap: 1vw;
    z-index: 9;

    svg {
        width: 100%;
        height: 100%;
    }

    &.success-box {
        background: #4A7023;
    }

    &.error-box {
        background: #990000;
    }

    svg {
        fill: #fff;
    }

    &.isClose {
        top: -10vw;
    }
}

.contact-container {
    color: #fff;
    overflow: hidden;
    margin: 4.8rem 0 0;

    .contact-bg {
        height: 24vw;
        width: auto;
    }

    .contact-title {
        font-size: 3vw;
        margin: -12vw 3vw 12vw;
        letter-spacing: 0.3vw;
        text-align: center;
    }

    .contact-content {
        text-align: left;
        padding: 1.2vw 15vw 3vw;
        display: grid;
        grid-template-columns: 36% 64%;
        
        .sm-container {
            .contact-item {
                display: grid;
                grid-template-columns: 15% 85%;
                align-items: center;
                margin: 0 0 1.2vw;
                height: 2.1vw;
                cursor: pointer;
                transition: linear 0.3s;
                font-size: 0.9vw;
                padding: 0 1vw 0 0;

                &:hover {
                    color: #aaa;

                    svg {
                        fill: #aaa;
                    }
                }

                svg {
                    width: 2.1vw;
                    height: 2.1vw;
                    margin: 0 1.2vw 0 0;
                    transition: linear 0.3s;
                }
            }

            .sm-item-container {
                display: flex;
                margin: 2.1vw 0 0;
                .sm-item {
                    cursor: pointer;
                    
                    &:hover {
                        svg {
                            fill: #aaa;
                        }
                    }

                    svg {
                        width: 2.1vw;
                        height: 2.1vw;
                        margin: 0 1.2vw 0 0;
                        transition: linear 0.3s;
                    }
                }
            }
        }
        
        .contact-form {
            h1 {
                font-size: 1.8vw;
                margin: 0 0 0.3vw;
            }
            .sub-header {
                color: #aaa;
                font-size: 0.9vw;
                margin: 0 0 1.2vw;
            }

            .form-field-container {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 3%;
                margin: 0 0 0.3vw;
            }

            .submit-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 0 0 auto;
                height: 3vw;
                width: 50%;
                padding: 0 3vw;
                font-size: 1.2vw;
                line-height: 3vw;
                background: #000;
                border: #fff solid 0.12vw;
                color: #fff;
                cursor: pointer;
                transition: linear 0.3s;
                
                &:hover {
                    background: #515151;
                }

                p {
                    margin: 0;
                }

                svg {
                    margin: 0 0 0 0.6vw;
                    width: 1.5vw;
                    height: 1.5vw;
                }
            }

            .form-field {
                label {
                    display: block;
                    font-size: 1.2vw;
                    margin: 0 0 0.6vw;
                }

                input, textarea {
                    height: 2.1vw;
                    font-size: 1.2vw;
                    padding: 0 0.6vw;
                    width: calc(100% - 1.2vw);
                    outline: none;
                    border: none;
                    transition: linear 0.3s;

                    &:focus {
                        background: #515151;
                        color: #fff;
                    }
                }

                textarea {
                    padding: 0.6vw;
                    height: 15vw;
                }

                .error-container {
                    height: 1.5vw;
                    display: flex;
                    align-items: center;
                    font-size: 0.9vw;
                    color: #ff0000;
                }
            }
        }
    }
    
    
}