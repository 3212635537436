
.gallery-slider-container {
    display: flex;
    position: relative;
    justify-content: center;
    padding: 21vh 0 0;

    // Start for slide index handling
    .slide-item-main,
    .slide-item-1,
    .slide-item-2,
    .slide-item-3,
    .slide-item-4,
    .slide-item--1,
    .slide-item--2,
    .slide-item--3,
    .slide-item--4,
    .slide-item-right,
    .slide-item--left {
        position: absolute;
        z-index: 9;
        left: 0;
        transition: linear 0.3s;
    }

    .slide-item-main {
        position: absolute;
        z-index: 5;
        opacity: 1;
        left: 38vw;

        .slide-title {
            opacity: 1 !important;
            visibility: visible !important;
        }
        // transform: translateX(calc(35%)) scale(1);
    }

    .slide-item-right {
        opacity: 0;
        left: -1.5vw;
        transform: scale(0.5);
        transition: linear 0.3s;
        z-index: 0;
    }

    .slide-item--left {
        opacity: 0;
        left: 77.5vw;
        transform: scale(0.5);
        transition: linear 0.3s;
        z-index: 0;
    }

    .slide-item-1 {
        opacity: 1;
        left: 23vw;
        z-index: 4;
        transform: scale(0.9);

        &:hover {
            opacity: 1;
            z-index: 7;
            transform: scale(0.95);
        }
    }

    .slide-item-2 {
        opacity: 1;
        left: 12vw;
        z-index: 3;
        transform: scale(0.8);

        &:hover {
            opacity: 1;
            z-index: 7;
            transform: scale(0.85);
        }
    }
    
    .slide-item-3 {
        opacity: 1;
        left: 5vw;
        z-index: 2;
        transform: scale(0.7);

        &:hover {
            opacity: 1;
            z-index: 7;
            transform: scale(0.75);
        }
    }

    .slide-item-4 {
        opacity: 1;
        left: 1vw;
        z-index: 1;
        transform: scale(0.6);

        &:hover {
            opacity: 1;
            z-index: 7;
            transform: scale(0.65);
        }
    }

    .slide-item--1 {
        opacity: 1;
        left: 53vw;
        z-index: 4;
        transform: scale(0.9);

        &:hover {
            opacity: 1;
            z-index: 7;
            transform: scale(0.95);
        }
    }

    .slide-item--2 {
        opacity: 1;
        left: 64vw;
        z-index: 3;
        transform: scale(0.8);

        &:hover {
            opacity: 1;
            z-index: 7;
            transform: scale(0.85);
        }
    }
    
    .slide-item--3 {
        opacity: 1;
        left: 71vw;
        z-index: 2;
        transform: scale(0.7);

        &:hover {
            opacity: 1;
            z-index: 7;
            transform: scale(0.75);
        }
    }

    .slide-item--4 {
        opacity: 1;
        left: 75vw;
        z-index: 1;
        transform: scale(0.6);

        &:hover {
            opacity: 1;
            z-index: 7;
            transform: scale(0.65);
        }
    }
    // End for slide index handling


    .left-btn {
        position: fixed;
        left: 3vw;
        top: 42%;
        width: 3vw;
        height: 3vw;
        z-index: 9;
        cursor: pointer;
    }

    .right-btn {
        position: fixed;
        right: 3vw;
        top: 42%;
        width: 3vw;
        height: 3vw;
        z-index: 9;
        cursor: pointer;
    }

    .slide-image-container {
        width: 24vw;
        height: 18vw;
        overflow: hidden;
        cursor: pointer;

        &:hover {
            .img {
                transform: scale(1);
            }
        }

        .slide-title {
            opacity: 0;
            visibility: hidden;
            height: 3.6vw;
            font-size: 0.72vw;
            line-height: 0.9vw;
            display: grid;
            grid-template-columns: 70% 30%;
            align-items: center;

            .learn-more {
                justify-self: flex-end;
            }
        }

        .slide-image {
            width: 100%;
            height: 100%;
            background-size: cover !important;
            background-position: center !important;
        }

        .shadow-container {
            position: absolute;
            bottom: calc(-100% + 3.6vw);
            width: 24vw;
            height: calc(18vw);
            overflow: hidden;
            transform: scaleY(-1);
            z-index: -1;

            .slide-shadow {
                width: 100%;
                height: 100%;
                background-size: cover !important;
                background-position: top !important;
                border: none;
            }
        }

        .shadow-cover {
            position: absolute;
            bottom: -100%;
            left: -0.2%;
            height: 100%;
            width: 100.4%;
            background: -ms-linear-gradient(180deg, rgba(0, 0, 0, 0.12) 0, #000 66%); 
            background:  -o-linear-gradient(180deg, rgba(0, 0, 0, 0.12) 0, #000 66%);
            background:     linear-gradient(180deg, rgba(0, 0, 0, 0.12) 0, #000 66%);
        }
    }
    
}