.navbar-wrapper {
    position: fixed;
    top: 0;
    background: #000;
    height: 4.68rem;
    border-bottom: 0.12rem solid #fff;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 20% 60% 20%;
    transition: top 0.3s;
    z-index: 999;

    &.hideNav {
        top: -4.8rem;
    }

    .logo-container {
        padding: 0 0 0 2.4vw;
        
        img {
            height: 3rem;
            width: auto;
            cursor: pointer;
        }
    }

    .navbar-container {
        color: #fff;
        display: flex;
        justify-content: center;
        font-size: 1rem;
        font-weight: 600;

        .nav-item {
            margin: 1.2vw;
            transition: linear 0.12s;
            cursor: pointer;
            
            &:hover {
                color: #aaa;
            }
        }
    }

    .social-media-container {
        color: #fff;
        display: flex;
        justify-self: flex-end;

        .sm-item {
            height: 2.1rem;
            width: 2.1rem;
            margin: 0 1rem;
            cursor: pointer;

            svg {
                transition: linear 0.12s;
            }

            &:hover {
                svg {
                    fill: #aaa;
                }
            }

            &:last-child {
                margin: 0 2.4vw 0 1rem;
            }
        }
    }

    .navbar-ham {
        display: none;
    }
}
.nav-dropdown-container {
    display: none;
}