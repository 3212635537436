// @font-face {
// 	font-family: 'Helvetica-Bold';
// 	src: url('../data/font/Helvetica-Bold.ttf');
//     font-display: swap;
// }

// @font-face {
// 	font-family: 'Helvetica';
// 	src: url('../data/font/Helvetica.ttf');
//     font-display: swap;
// }

body {
    // font-family: 'Helvetica', Helvetica, sans-serif;
	font-family: 'Lato', Helvetica, Arial;
}