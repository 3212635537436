@media (max-width: 768px) {
    .home-container {
        overflow-x: hidden;
        .landing-wrapper {
            height: 150vw;

            .view-video-container {
                top: calc(6vw + 4.8rem);
                left: -24vw;

                &.isShowSlider {
                    left: 6vw;
                }

                &:hover {
                    svg {
                        fill: #fff;
                    }
                    p {
                        color: #fff;
                    }
                }

                svg {
                    width: 6vw;
                    height: 6vw;
                }

                p {
                    font-size: 3vw;
                }
            }

            .slider-3d-container {
                &.isShowSlider {
                    height: 142vw;
                }
            }

            .landing-container {
                height: 150vw;
                overflow: hidden;

                .landing-video {
                    margin: -21vw 0 0 -100vw;
                    height: 210vw;

                    div {
                        width: 300vw !important;
                    }
                }

                .landing-cover {
                    height: 150vw;
                }
            }
        }

        .home-about-container {
            display: grid;
            grid-template-columns: unset;
            align-items: center;
            height: 93vw;
            padding: 2.1vw 0;

            .head-container {
                .head-title {
                    font-size: 6vw;
                    margin: 3vw;
                }

                .img-container {
                    width: 100%;
                    height: 75vw;
                    margin-top: 12vw;
                    position: relative;
                    filter: opacity(0.24);
                    box-shadow: inset 0px 0px 9vw 6vw #fdfdfd;
                }
            }

            .content-container {
                position: relative;
                z-index: 2;
                text-align: center;
                margin: -96vw 0 0;
                color: #000;

                h1 {
                    font-size: 5.4vw;
                    margin: 3vw;
                }

                .desc-container {
                    width: 90%;
                    font-size: 3.12vw;
                    margin: 2.1vw auto;
                }
            }
        }

        .home-portfolio-title {
            margin: 12vw 0 6vw;
            h1 {
                font-size: 6vw;
            }

            h3 {
                font-size: 3vw;
                letter-spacing: 0.42vw;
            }
        }

        .home-portfolio-container {
            grid-template-columns: repeat(2, 1fr);

            .pf-item {
                height: auto;
                width: 100%;
                padding: 0;
                flex-wrap: wrap;

                .pf-desc {
                    display: block;
                    color: #fff;
                    font-size: 3vw;
                    text-align: center;
                    margin: 1.8vw 0 1.2vw;
                }

                .pf-image {
                    height: auto;
                    width: 100%;
                }

                &:hover {
                    .pf-content {
                        top: 60%;
                    }
                }

                .pf-content {
                    display: none;
                }
            }
        }

        .client-title {
            margin: 6vw 0 0;
            padding: 3vw 0 0;
            h1 {
                font-size: 6vw;
            }

            h3 {
                font-size: 3vw;
                letter-spacing: 1vw;
            }
        }

        .home-client-container {
            flex-wrap: nowrap;
            overflow: scroll;
            justify-content: flex-start;
            align-items: center;
            width: 101%;

            .client-item {
                height: auto;
                width: auto;
                max-width: unset;
                padding: 0 3vw;

                &:nth-of-type(1) {
                    padding: 0 6vw 0 3vw;
                }

                .client-image {
                    height: auto !important;
                    width: 30vw !important;
                }
            }
        }
    }
}