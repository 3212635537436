
@import './font.scss';

body {
    padding: 0;
    margin: 0;
    letter-spacing: 0.1vw;
    background: #000;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none;
}

svg, img {
    width: 100%;
    height: 100%;
}

/* width */
::-webkit-scrollbar { width: 0.6vw; height: 0; }

/* Track */
::-webkit-scrollbar-track { background: #333; }

/* Handle */
::-webkit-scrollbar-thumb { background: #888; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover { background: #555; }


// loading icon style
.loading-icon-container {
    width: 100%;
    display: grid;
    place-items: center;

    svg {
        fill: #fff;
        width: 3vw;
        height: 3vw;
        -webkit-animation: spin 1.2s linear infinite;
        -moz-animation: spin 1.2s linear infinite;
        animation: spin 1.2s linear infinite;
    }
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}


@import './navbar/navbar.scss';
@import './navbar/m-navbar.scss';
@import './footer/footer.scss';
@import './footer/m-footer.scss';
@import './home/home.scss';
@import './home/m-home.scss';
@import './portfolio/portfolio-landing.scss';
@import './portfolio/m-portfolio-landing.scss';
@import './portfolio/portfolio.scss';
@import './portfolio/m-portfolio.scss';
@import './contact/contact.scss';
@import './contact/m-contact.scss';
@import './pagination.scss';
@import './gallerySlider3D/gallerySlider3D.scss';
@import './gallerySlider3D/m-gallerySlider3D.scss';