
.pf-content {
    display: none;
}

.category-popup-container {
    display: none;
}

.filter-container {
    display: none;
}

@media (max-width: 768px) {
    .portfolio-landing-wrapper {
        transition: linear 0.3s;
        &.filtersOpen {
            padding-top: 32vh;
        }

        .portfolio-list-container {
            grid-template-columns: repeat(2, 1fr);
            padding: 15vw 3.6vw 0;
            grid-gap: 2.1vw;
            transition: linear 0.3s;

            &.hideNav {
                padding: 6vw 3.6vw 0;
            }

            .pf-content {
                color: #fff;
                margin: 0;
                font-size: 3vw;
                text-align: center;
                display: block;

                p {
                    margin: 1.2vw 0;
                }
            }

            .pf-item {
                position: relative;
                margin-bottom: 2.1vw;
                height: 66%;

                .pf-cover {
                    display: none;
                }
            }
        }

        .category-container {
            display: none;
        }

        .filter-container {
            position: fixed;
            display: block;
            height: 30vh;
            width: 100%;
            background: #000;
            top: calc(-30vh - 0.18vw);
            z-index: 15;
            border-bottom: #fff 0.18vw solid;
            transition: linear 0.3s;
            color: #fff;

            .header-section {
                display: flex;
                align-items: center;
                height: 9vw;
                justify-content: space-between;
                padding: 0 3vw;
                margin: 3vw 0 0;

                p {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    // height: 6vw;
                    margin: 0;
                    font-size: 4.8vw;
                }

                svg {
                    width: 4.8vw;
                    height: 4.8vw;
                    margin: 0 1.5vw 0 0;
                }
            }

            .category-section {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 2.1vw;
                padding: 0 3vw;
                font-size: 4.2vw;
                text-transform: capitalize;
                margin: 3vw 0 0;

                .category-item {
                    padding: 3vw 0;
                    text-align: center;
                    border: 0.6vw transparent solid;
                    transition: border 0.3s;
                    cursor: pointer;

                    &.isSelected {
                        border: 0.6vw solid #fff;
                    }
                }
            }
            
            &.hideNav {
                top: calc(-30vh);

                &.isShow {
                    top: 12vw;
                }
            }

            &.isShow {
                top: calc(12vw + 4.8rem);
            }
        }

        .category-popup-container {
            position: fixed;
            display: block;
            top: 4.8rem;
            left: 0;
            width: 100%;
            z-index: 99;
            transition: linear 0.3s;

            &.hideNav {
                top: 0;
            }
    
            .filter-btn {
                background: #fff;
                width: 100%;
                height: 12vw;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 3.6vw;
                font-weight: 600;
    
                svg {
                    fill: #000;
                    width: 4.8vw;
                    height: 4.8vw;
                    margin: 0 1.2vw 0 0;
                }
            }
            
        }

        .no-port-container {
            margin: 6vw 0 12vw;

            p {
                font-size: 4.2vw;
                width: 60vw;
                margin: 6vw auto 0;
            }

            svg {
                width: 60vw;
                height: 30vw;
            }
        }
    }
}