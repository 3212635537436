
.portfolio-landing-wrapper {
    margin: 4.8rem 0 0;
    
    .loading-icon-container {
        height: 30vw;
    }

    .category-container {
        display: flex;
        color: #fff;
        align-items: center;
        padding: 3vw 3.6vw 0;
        text-transform: uppercase;
        font-size: 1vw;
        font-weight: 600;

        .filter-title {
            display: flex;
            align-items: center;
            margin: 0 0.6vw 0 0;

            svg {
                width: 1vw;
                height: 1vw;
                padding: 0 0.6vw 0 0;
            }
        }

        .clear-btn {
            margin: 0 0.6vw;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: linear 0.3s;
            opacity: 0;
            visibility: hidden;

            &.isShow {
                opacity: 1;
                visibility: visible;
            }

            &:hover {
                color: #aaa;
            }

            svg {
                width: 1.2vw;
                height: 1.2vw;
                padding: 0 0.42vw 0 0;
            }
        }

        .category-item {
            margin: 0 0.6vw;
            padding: 0.3vw 0.6vw;
            border: 0.21vw transparent solid;
            transition: border 0.3s;
            cursor: pointer;

            &.isSelected {
                border: 0.21vw solid #fff;

                &:hover {
                    border: 0.21vw solid #aaa;
                }
            }
        }

        .hint-arrow {
            height: 3vw;
            width: 3vw;
            margin: 0 0 0 2.1vw;
            -moz-animation: bounce 1.8s both linear infinite;
            -webkit-animation: bounce 1.8s both linear infinite;
            animation: bounce 1.8s both linear infinite;
            opacity: 0;
            visibility: hidden;
            transition: linear 0.3s;

            &.isShow {
                opacity: 1;
                visibility: visible;
                transition: linear 0.3s;
            }
        }
    }

    .portfolio-list-container {
        padding: 2.1vw 3.6rem;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 0.3vw;

        .pf-item {
            cursor: pointer;
            overflow: hidden;
            position: relative;
            height: 9vw;

            img {
                transform: scale(1.1, 1.1);
                transition: transform 0.3s;
                width: 110%;
                margin: 0 0 0 -5%;
                height: auto;
            }

            .pf-cover {
                width: 90%;
                height: calc(100% - 1.2vw);
                position: absolute;
                top: 100%;
                left: -1%;
                z-index: 9;
                text-align: center;
                padding: 0 6% 1.2vw;
                background: rgba(0, 0, 0, 0.6);
                font-size: 0.6vw;
                transition: top 0.3s;
                display: grid;
                align-content: flex-end;
                grid-auto-rows: minmax(min-content, max-content); // magic happen!
                color: #fff;

                p {
                    margin: 0.9vw 0;
                    color: #000;
                    transition: color 0.9s;
                }

                hr {
                    width: 36%;
                    height: 0.12vw;
                    border: none;
                    background: #fff;
                }

                .category-word {
                    font-size: 0.9vw;
                    margin: 0 0 0.3vw;
                    text-transform: uppercase;
                }
            }

            &:hover {
                img {
                    transform: scale(1, 1);
                    transition: transform 0.3s;
                }

                .pf-cover {
                    top: 0;

                    p {
                        color: #fff;
                        transition: color 0s;
                    }
                }
            }
        }
    }

    .no-port-container {
        color: #fff;
        text-align: center;
        width: 100%;

        svg {
            width: 12vw;
            height: 15vw;
        }

        p {
            font-size: 1.5vw;
        }
    }
}

@-moz-keyframes bounce {
    0%, 100% {
        -webkit-transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(-2.1vw);
    }
}
@-webkit-keyframes bounce {
    0%, 100% {
        -webkit-transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(-2.1vw);
    }
}
@keyframes bounce {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-2.1vw);
    }
}