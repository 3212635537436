.portfolio-container {
    .next-prev-container {
        .short-dir {
            display: none;
        }
    }
}

@media (max-width: 768px) {
    .portfolio-container {
        padding: 15vw 0 0;
        
        .bg-cover-container {
            width: 150%;
            top: 0;
            left: -25%;
        }

        .next-prev-container {
            position: fixed;
            top: 4.8rem;
            margin: 0 auto;
            padding: 0 6vw;
            width: 88vw;
            font-size: 3.6vw;
            height: 12vw;
            grid-template-columns: 40% 20% 40%;
            transition: linear 0.21s;

            &.hideNav {
                top: 0;
                transition: linear 0.3s;
            }

            .next-btn, .prev-btn {
                svg {
                    width: 6vw;
                    height: 6vw;
                }

                &:hover {
                    color: #000;
                }
            }

            .prev-btn {
                margin: 0 3.6vw 0 0;
            }

            .catalog-btn {
                justify-self: center;
                align-self: center;

                svg {
                    display: none;
                }
            }

            p {
                display: none;
            }
            .short-dir {
                display: block !important;
                margin: 0 3vw !important;
            }

            
        }

        .back-btn {
            display: none;
        }

        .nav-pop {
            display: none;
        }

        .content-wrapper {
            margin: 0 6vw;
            height: 60vw;

            .loading-wrapper {
                margin: 0 0 -60vw;
            }

            .port-video {
                height: 57vw;
            }

            .gallery-container {
                .react-3d-carousel {
                    .slider-container {
                        top: 0;

                        .slider-content {
                            .slider-single {
                                height: 60vw;

                                &:hover {
                                    &.active {
                                        .slider-single-content {
                                            height: 100vh;
                                            position: absolute;
                                            left: -19vw;
                                            top: 0;
                                            width: 88vw;
                                            padding: 0 6vw;
                                            display: grid;
                                            align-items: center;

                                            img {
                                                &:hover {
                                                    transform: scale(1, 1);
                                                }
                                            }
                                        }
                                    }
                                }
                                .slider-single-content {
                                    display: grid;
                                    img {
                                        max-height: 60vw;
                                        width: auto;
                                        height: auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .port-content-wrapper {
            width: calc(100% - 12vw);
            padding: 0 6vw;
            margin: 0 auto;
            background: transparent;

            .port-title {
                font-size: 3.6vw;
                margin: 0 0 3vw;
                line-height: 5.4vw;
            }

            hr {
                margin: 0;
            }

            .content-container {
                font-size: 3vw;
                grid-template-columns: none;

                .port-desc-container {
                    order: 2;
                    padding: 3vw 0;

                    h3 {
                        margin: 0 0 2.1vw;
                        font-size: 3vw;
                    }
                    .port-desc {
                        p {
                            font-size: 3vw;
                        }
                    }
                }

                .port-share {
                    order: 1;
                    border: none;
                    border-bottom: 0.15vw solid #aaa;
                    padding: 3vw 0;

                    .category-container {
                        margin: 0 0 2.1vw;
                        h3 {
                            font-size: 3vw;
                        }
                        .category-item-container {
                            .category-item {
                                font-size: 3vw;
                            }
                        }
                    }

                    .share-container {
                        display: grid;
                        h3 {
                            font-size: 3vw;
                        }
                        .sm-container {
                            display: flex;
                            justify-content: space-between;
                            width: 60%;

                            svg {
                                height: 5.7vw;
                                width: 5.7vw;
                                margin: 0 0.9vw;
                            }
                        }
                    }
                }
            }

            .bts-container {
                display: grid;
                grid-template-columns: 30% 67%;
                grid-gap: 3%;
                align-items: flex-start;
                flex-wrap: nowrap;
                padding: 3vw 0;

                svg {
                    left: 65%;
                }

                .bts-title {
                    align-items: flex-start;
                    height: 100%;
                    font-size: 3vw;
                    margin: 0 0 2.1vw;
                }
            }
            
            .sub-video-container {
                padding: 3vw 0;
                .sub-video-title {
                    height: 6vw;
                    font-size: 3vw;
                    margin: 0 0 2.1vw;
                }

                .sub-video {
                    height: 50vw;
                }
            }
        }
    }
}