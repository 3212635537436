
@media (max-width: 768px) {

    .notification-modal {
        top: 27vw;
        font-size: 3vw;
        width: 72vw;
        right: 12vw;
        height: 15vw;


        &.isClose {
            top: -20vw;
        }
    }

    .contact-container {
        .contact-bg {
            height: 50vw;
        }

        .contact-title {
            font-size: 6vw;
            margin: -21vw 3vw 18vw;
        }

        .contact-content {
            grid-template-columns: none;
            padding: 3vw 9vw;

            .sm-container {
                .contact-item {
                    height: 9.6vw;
                    font-size: 2.7vw;
                    padding: 0;
                    margin: 0;

                    svg {
                        width: 7.2vw;
                        height: 7.2vw;
                    }

                    &:hover {
                        color: #fff;
    
                        svg {
                            fill: #fff;
                        }
                    }
                }

                .sm-item-container {
                    margin: 2.1vw 0 3.6vw;
                    .sm-item {
                        width: 9vw;
                        height: 9vw;
                        display: flex;
                        align-items: center;
                        margin: 0 1.2vw 0 0;

                        svg {
                            width: 7.2vw;
                            height: 7.2vw;
                        }
                    }
                }
            }

            .contact-form {
                margin: 3vw 0 0;
                h1 {
                    font-size: 5.4vw;
                    margin: 0 0 1.5vw;
                }

                .sub-header {
                    font-size: 3vw;
                    margin: 0 0 3vw;
                }

                .form-field-container {
                    grid-template-columns: none;
                    margin: 0 0 7.5vw;
                }

                .form-field {
                    label {
                        font-size: 3.6vw;
                        margin: 0 0 1.2vw;
                    }

                    input {
                        font-size: 3.6vw;
                        height: 6vw;
                    }

                    .error-container {
                        font-size: 3.6vw;
                        height: 5.4vw;
                        display: flex;
                        align-items: center;
                        transition: linear 0.3s;

                        &.isHide {
                            font-size: 0;
                            height: 1.2vw;
                        }
                    }

                    &.msg-container {
                        textarea {
                            font-size: 3.6vw;
                            height: 36vw;
                        }
                    }
                }

                .submit-btn {
                    width: 100%;
                    font-size: 4.5vw;
                    height: 9vw;

                    svg {
                        width: 4.5vw;
                        height: 4.5vw;
                        margin: 0 0 0 2.1vw;
                    }
                }
            }
        }
    }
}