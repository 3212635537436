
.home-container {
    margin: 4.8rem 0 0;

    .landing-wrapper {
        height: 100vh;

        .loading-icon-container {
            height: 100%;
        }

        .landing-container {
            height: 100vh;
            transform: scale(1, 1);
            transition: linear 0.3s;
    
            &.isShowSlider {
                height: 0;
                transform: scale(0, 0);
                opacity: 0;
                visibility: hidden;
            }
    
            .landing-video {
                height: 100vh;
                pointer-events: none;
            }
        
            .landing-cover {
                position: absolute;
                top: 0;
                height: 100vh;
                width: 100%;
                z-index: 9;
                cursor: pointer;
            }
        }

        .view-video-container {
            color: #fff;
            position: absolute;
            left: -12vw;
            top: calc(3vw + 4.8rem);
            text-align: center;
            transition: left 0.9s;
            cursor: pointer;

            svg {
                height: 2.1vw;
                width: 2.1vw;
                transition: linear 0.3s;
            }

            p {
                margin: 0;
                font-size: 0.72vw;
                transition: linear 0.3s;
            }

            &:hover {
                p {
                    color: #aaa;
                }

                svg {
                    fill: #aaa;
                }
            }

            &.isShowSlider {
                left: 3vw;
                transition: left 0.9s;
            }
        }
        
    
        .slider-3d-container {
            height: 0;
            transform: scale(0, 0);
            visibility: hidden;
            opacity: 0;
            color: #fff;
            display: grid;
            padding: 4.2vh 0 0;
            overflow: hidden;
            position: relative;
            transition: linear 0.9s;
            background: #000;
    
            &.isShowSlider {
                height: 100vh;
                transform: scale(1, 1);
                visibility: visible;
                opacity: 1;
                transition: linear 0.9s;
            }
    
            .react-3d-carousel {
                .slider-container {
                    .slider-left div,
                    .slider-right div {
                        border: 0.21vw solid #fff;
                        border-width: 0 0.21vw 0.21vw 0;
                        display: inline-block;
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                    }
    
                    .slider-left div {
                        transform: rotate(135deg);
                        -webkit-transform: rotate(135deg);
                    }
                }
    
                .slide-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-transform: uppercase;
                    height: 3.9vw;
                    margin: 0;
    
                    p {
                        font-size: 1vw;
                        margin: 0;
                    }
    
                    .learn-more {
                        opacity: 0;
                        visibility: hidden;
                        transition: linear 0.6s;
                    }
                }
    
                .slider-single.active {
                    .learn-more {
                        cursor: pointer;
                        opacity: 1;
                        visibility: visible;
                        transition: linear 0.6s;
    
                        &:hover {
                            color: #aaa;
                        }
                    }
                }
    
                .slide-image-container {
                    position: relative;

                    .slide-image {
                        cursor: pointer;
                    }
        
                    .slide-shadow {
                        position: absolute;
                        bottom: calc(-99.6% - 3.9vw);
                        transform:scaleY(-1);
                        z-index: 6;
                        left: 0;
                        backdrop-filter: blur(4px);
                    }
        
                    .shadow-cover {
                        position: absolute;
                        bottom: calc(-99.6% - 3.9vw);
                        left: 0;
                        z-index: 9;
                        height: 100%;
                        width: 120%;
                        background: -ms-linear-gradient(180deg, rgba(0, 0, 0, 0.12) 0, #000 15%); 
                        background:  -o-linear-gradient(180deg, rgba(0, 0, 0, 0.12) 0, #000 15%);
                        background:     linear-gradient(180deg, rgba(0, 0, 0, 0.12) 0, #000 15%);
                    }
                }

                .proactivede:nth-of-type(1) .slider-single-content {
                    opacity: 0.6 !important;
                    transform: translateX(-50%) scale(0.6) !important;
                }

                .slider-single {
                    &.active {

                    }

                    &.proactive {
                        .slider-single-content {
                            opacity: 0.72;
                            transform: translateX(25%) scale(0.9);
                        }
                    }

                    &.preactive {
                        .slider-single-content {
                            opacity: 0.72;
                            transform: translateX(-25%) scale(0.9);
                        }
                    }

                    &.proactivede .slider-single-content {
                        opacity: 0.3;
                        transform: translateX(50%) scale(0.6);
                    }

                    // &.proactivede:first-of-type() .slider-single-content {
                    //     opacity: 0.6;
                    //     transform: translateX(-50%) scale(0.6) !important;
                    // }

                    // &.proactivede {
                    //     &:first-child() {
                    //         .slider-single-content {
                    //             opacity: 0.3;
                    //             transform: translateX(-50%) scale(0.6);
                    //         }
                    //     }
                    // }
                }
            }
        }
    }

    

    .home-about-container {
        width: 100%;
        background: #fdfdfd;
        color: #516c7a;
        display: grid;
        grid-template-columns: 30% 67%;
        grid-gap: 3%;
        height: 27vw;
        margin: 2.1vw 0 0;
        padding: 2.1vw 0;

        .head-container {
            text-align: center;

            .head-title {
                height: 4.8vw;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.5vw;
                font-weight: 600;
                padding: 1.2vw 0 0;
                font-size: 1.5vw;
            }

            .img-container {
                box-shadow: inset 0px 0px 2.1vw 3vw #fdfdfd;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                height: 21vw;
            }
        }

        .content-container {
            h1 {
                margin: 2.1vw 0 0.9vw;
                font-size: 2.1vw;
            }

            .desc-container {
                font-size: 1.2vw;
                width: 90%;
                margin: 1.2vw 0;
            }
        }
    }

    .home-portfolio-title {
        color: #fff;
        width: 100%;
        text-align: center;

        .header {
            margin: 6vw 0 0;
            font-size: 3.6vw;
            font-weight: 600;
        }

        .subHeader {
            font-size: 1.8vw;
            letter-spacing: 0.33vw;
            margin: 0 0 2.1vw;
            font-weight: 600;
        }
    }

    .home-portfolio-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin: 1.2vw 6vw;
        grid-gap: 2.1vw;

        .pf-item {
            height: 24vw;
            overflow: hidden;
            display: flex;
            justify-content: center;
            cursor: pointer;
            position: relative;

            .pf-inner-wrapper {
                overflow: hidden;
            }

            .pf-desc {
                display: none;
            }

            img {
                width: auto;
                transform: scale(1.1, 1.1);
                transition: transform 0.3s;
            }

            .pf-content {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 9;
                height: 100%;
                background: rgba(0, 0, 0, 0.72);
                color: #fff;
                transition: top 0.3s;
                display: grid;
                align-items: flex-end;
                justify-content: center;
                text-align: center;
                padding: 0 3vw;
                font-size: 1.5vw;

                .pf-inner {
                    padding: 0 0 1.5vw;
                    hr {
                        width: 36%;
                        height: 0.12vw;
                        border: none;
                        background: #fff;
                    }
                    p {
                        width: 100%;
                        margin: 0.9vw 0 0;
                    }
                }
            }

            &:hover {
                img {
                    transform: scale(1, 1);
                }

                .pf-content {
                    top: 0;
                }
            }
        }
    }


    .client-title {
        color: #000;
        background: #fff;
        margin: 0;
        text-align: center;

        .header {
            margin: 3vw 0 0;
            padding: 3vw 0 0;
            font-size: 3.6vw;
            font-weight: 600;
        }

        .subHeader {
            font-size: 1.8vw;
            letter-spacing: 0.57vw;
            padding: 0 0 0 0.3vw;
            font-weight: 600;
            margin: 0;
        }
    }

    .home-client-container {
        display: flex;
        flex-wrap: wrap;
        overflow-x: scroll;
        justify-content: center;
        width: 100%;
        background: #fff;
        padding: 3.6vw 0 4.2vw;
        margin: -0.6vw 0 0;

        .client-item {
            height: 3vw;
            width: auto;
            max-width: 10%;
            margin: 0 1.5vw;
        }
    }
}