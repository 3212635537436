@media (max-width: 768px) {
    .gallery-slider-container {

        .left-btn,
        .right-btn {
            top: 50%;
            width: 6.3vw;
            height: 6.3vw;
        }

        .slide-image-container {
            width: 42vw;
            height: 36vw;

            .slide-title {
                font-size: 2.1vw;
                line-height: 3vw;
                height: 9vw; 
                grid-template-columns: none;
                text-align: center;
                overflow: hidden;

                .learn-more {
                    display: none;
                }
            }

            .shadow-container {
                bottom: calc(-100% + 3.6vw);
                width: 42vw;
                height: 36vw;
            }

            .shadow-cover {
                left: -0.6%;
                width: 101.2%;
            }
        }

        .slide-item-4,
        .slide-item--4,
        .slide-item-right,
        .slide-item--left {
            display: none;
        }

        .slide-item-3 {
            opacity: 0;
            left: -5vw;
            transform: scale(0.6);
            transition: linear 0.3s;
            z-index: 0;

            &:hover {
                opacity: 0;
            }
        }
    
        .slide-item--3 {
            opacity: 0;
            left: 63vw;
            transform: scale(0.6);
            transition: linear 0.3s;
            z-index: 0;

            &:hover {
                opacity: 0;
            }
        }

        .slide-item-1 {
            left: 13vw;

            &:hover {
                transform: scale(0.9);
                z-index: 4;
            }
        }

        .slide-item-2 {
            left: 1vw;
            transform: scale(0.75);

            &:hover {
                transform: scale(0.75);
                z-index: 3;
            }
        }

        .slide-item--1 {
            left: 45vw;

            &:hover {
                transform: scale(0.9);
                z-index: 4;
            }
        }

        .slide-item--2 {
            left: 57vw;
            transform: scale(0.75);

            &:hover {
                transform: scale(0.75);
                z-index: 3;
            }
        }

        .slide-item-main {
            left: 29vw;
        }
    }
}