
@media (max-width: 768px) {
    .footer-wrapper {
        .footer-contact-container {
            display: none;
        }

        .footer-credit {
            margin: 6vw 0 3vw;
            font-size: 3vw;
        }
    }
}