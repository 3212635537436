
.dropdown-overlay {
    display: none;
}

@media (max-width: 768px) {
    .dropdown-overlay {
        display: block;
        position: fixed;
        background: transparent;
        top: 4.8rem;
        height: 100vh;
        width: 100vw;
        z-index: 21;
    }

    .nav-dropdown-container {
        position: fixed;
        display: block;
        color: #fff;
        z-index: 998;
        background: #000;
        width: 100%;
        top: calc(-87vw + 4.68rem);
        transition: top 0.3s;
        border-bottom: 0.12rem solid #fff;
        max-height: 60vh;
        overflow: scroll;

        .nav-container {
            text-align: center;

            .nav-item {
                margin: 4.2vw 0;
                font-size: 5.4vw;
                height: 12vw;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .sm-container {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: 6vw 0 0;

            .sm-title {
                width: 100%;
                margin: 0;
                font-size: 4.8vw;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 3vw 0;

                svg {
                    width: 5.4vw;
                    height: 5.4vw;
                    margin: 0 2.1vw 0 0;
                }
            }

            .sm-item {
                margin: 0 2.4vw;
                svg {
                    width: 6.6vw;
                    height: 6.6vw;
                }
            }
        }

        .nav-contact-container {
            margin: 12vw 0 6vw;
            .contact-item {
                display: flex;
                margin: 3vw 0;

                p {
                    width: 100%;
                    margin: 0;
                    font-size: 3.6vw;
                }
                svg {
                    width: 30%;
                    margin: 0 0 2.1vw;
                    height: 6vw;
                }
            }
        }

        .credit-container {
            width: 100%;
            margin: 4.2vw 0 6vw;
            text-align: center;
            font-size: 3vw;
        }

        &.isOpen {
            top: 4.8rem;

            &.hideNav {
                top: 4.8rem !important;
            }
        }

        &.hideNav {
            top: -99vw !important;

            &~.category-popup-container {
                top: 0;
            }
        }
    }

    .navbar-wrapper {
        grid-template-columns: 80% 20%;
        border-bottom: 0.12rem solid transparent;

        &.isNavOpen {
            border-bottom: 0.12rem solid transparent;

            &.hideNav {
                top: 0;
            }
        }

        .logo-container {
            padding: 0 0 0 3vw;
        }

        .navbar-container {
            display: none;
        }
    
        .social-media-container {
            display: none;
        }
    
        .navbar-ham {
            display: block;
            justify-self: flex-end;
            align-self: center;
            padding: 0 4.2vw 0 0;
    
            svg {
                stroke: #fff;
                fill: #fff;
                width: 2.1rem;
                height: 2.1rem;
            }
        }
    }
}

@media screen and (orientation:landscape) {

}