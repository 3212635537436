
.footer-wrapper {
    color: #fff;

    .footer-contact-container {
        display: flex;
        justify-content: center;
        padding: 3vw 0 1.5vw;
        font-size: 1vw;

        .contact-item {
            display: flex;
            align-items: center;
            margin: 0 1.2vw;
            cursor: pointer;
    
            p {
                margin: 0;
            }
    
            svg {
                height: 1.5vw;
                width: 1.5vw;
                margin: 0 0.9vw 0 0;
            }

            &:hover {
                color: #aaa;

                svg {
                    fill: #aaa;
                }
            }
        }
    }

    .footer-credit {
        display: flex;
        justify-content: center;
        padding: 1.2vw 0 3vw;
        font-size: 1vw;
    }
    
}