.portfolio-container {
    margin: 4.8rem 0 0;
    color: #fff;
    width: 100%;
    padding: 4.2vw 0 0;

    .back-btn {
        position: fixed;
        left: 2.1vw;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: linear 0.3s;
        top: calc(2.1vw + 4.8rem);
        z-index: 99;

        &:hover {
            color: #aaa;
        }

        svg {
            width: 1.8vw;
            height: 1.8vw;
            margin: 0 0.6vw 0 0;
        }

        p {
            font-size: 1.2vw;
            margin: 0;
        }
    }

    .nav-pop {
        position: fixed;
        width: 9vw;
        text-align: center;
        font-weight: 600;
        left: 3vw;
        bottom: 3vw;

        p {
            margin: 0.3vw 0;
            font-size: 1.2vw;
        }

        svg {
            width: 3vw;
            height: 3vw;
            -moz-animation: bounceDown 1.8s both linear infinite;
            -webkit-animation: bounceDown 1.8s both linear infinite;
            animation: bounceDown 1.8s both linear infinite;
        }
    }

    .bg-cover-container {
        position: fixed;
        top: -6vw;
        z-index: -1;
        width: 100%;

        .cover-cover {
            background: linear-gradient(180deg, transparent, #000);
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    .content-wrapper {
        height: 81vh;
        width: auto;
        margin: 0 15vw;

        &.gallery-type {
            margin: 0 15vw 6vw;
        }

        .loading-wrapper {
            height: 100%;
            opacity: 1;
            visibility: visible;
            transition: linear 0.3s;
            margin: 0 0 -81vh;

            &.isLoaded {
                opacity: 0;
                visibility: hidden;
            }
        }

        .loading-icon-container {
            height: 100%;
        }

        .port-video {
            width: auto;
            height: 100%;
            margin: auto;
            transition: linear 0.3s;
            opacity: 1;
            visibility: visible;
        }

        .gallery-container {
            height: 100%;
            margin: 0 -15vw 3vw;

            .react-3d-carousel {
                height: 100% !important;

                .slider-container {
                    z-index: 997;

                    .slider-content {
                        .slider-single {
                            height: 80vh;
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            &:hover {
                                &.active {
                                    .slider-single-content {
                                        overflow: visible;
                                        // width: 90vw;
                                        // height: 100vh;
                                        // position: absolute;
                                        // left: -19vw;
                                        // top: -4.2vw;
                                        // padding: 5vw;
                                        
                                        img {
                                        }
                                    }
                                }
                            }

                            &.active {
                                .slider-single-content {
                                    box-shadow: none;
                                    img {
                                        &:hover {
                                            &~.slider-single-content {
                                                width: 90vw;
                                                height: 100vh;
                                                position: absolute;
                                                left: -19vw;
                                                top: -4.2vw;
                                                padding: 5vw;
                                            }
                                        }
                                    }
                                }
                            }

                            &.active .slider-single-content img:hover {
                                transform: scale(1.2, 1.2);
                            }

                            &.active .slider-single-content img:hover > .slider-single-content {
                                width: 90vw;
                                height: 100vh;
                                position: absolute;
                                left: -19vw;
                                top: -4.2vw;
                                padding: 5vw;
                            }

                            .slider-single-content {
                                max-height: 100%;
                                width: 100%;
                                overflow: hidden;
                                display: grid;
                                align-items: center;
                                background: transparent;
                                width: 100vw;

                                img {
                                    max-height: 100%;
                                    width: 100%;
                                    height: auto;
                                    transform: scale(1, 1);
                                    transition: linear 0.3s;
                                }
                            }
                        }
                    }

                    .slider-left div,
                    .slider-right div {
                        border: 0.21vw solid #fff;
                        border-width: 0 0.21vw 0.21vw 0;
                        display: inline-block;
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                    }
    
                    .slider-left div {
                        transform: rotate(135deg);
                        -webkit-transform: rotate(135deg);
                    }
                }
            }
        }
    }

    .port-content-wrapper {
        width: calc(100% - 30vw);
        padding: 3vw 15vw;
        margin: 3vw 0;
        background: rgba(0, 0, 0 , 0.6);

        .port-title {
            font-size: 1.5vw;
            margin: 0;
            padding: 0 0 2.1vw 0;
        }

        hr {
            background: #aaa;
            height: 0.12vw;
            border: 0;
            margin: 0.6vw 0;
        }

        .sub-video-container {
            .sub-video-title {
                margin: 0.6vw 0;
                display: flex;
                align-items: center;
                height: 1.8vw;
                font-size: 1.2vw;
                text-transform: uppercase;
                font-weight: 600;
            }

            .sub-video {
                height: 72vh;
                margin: 0 0 2.1vw;
            }
        }

        .bts-container {
            overflow: hidden;
            width: 100%;
            height: 30vw;
            position: relative;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 0 0 2.1vw;

            .bts-title {
                margin: 0.6vw 0;
                display: flex;
                align-items: center;
                height: 1.8vw;
                font-size: 1.2vw;
                text-transform: uppercase;
                width: 100%;
                font-weight: 600;
            }

            .bts-image {
                width: 100%;
                height: auto;
                cursor: pointer;
                &:hover {


                    ~svg {
                        fill: #aaa;
                        transform: scale(1.2, 1.2);
                    }

                    ~.bts-image-cover {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            .bts-image-cover {
                height: calc(30vw - 3vw);
                position: absolute;
                top: 3vw;
                width: 100%;
                height: 100%;
                z-index: 9;
                background: rgba(0, 0, 0, 0.6);
                pointer-events: none;
                opacity: 0;
                visibility: hidden;
                transition: linear 0.3s;
            }

            svg {
                position: absolute;
                width: 4.2vw;
                height: 4.2vw;
                left: calc(50% - 2.1vw);
                top: 50%;
                fill: #fff;
                z-index: 99;
                transform: scale(1, 1);
                transition: linear 0.3s;
            }
        }

        .content-container {
            display: grid;
            width: 100%;
            grid-template-columns: 60% 40%;
            padding: 1.5vw 0 0;

            .port-desc-container {
                padding: 0 3% 0 0;

                .port-desc {
                    p {
                        font-size: 1vw;
                        min-height: 1vw;
                        margin: 0.54vw 0;
                    }
                }

                h3 {
                    margin: 0.6vw 0;
                    font-size: 1.2vw;
                    display: flex;
                    align-items: center;
                    height: 1.8vw;
                    text-transform: uppercase;
                }
            }

            .port-share {
                padding: 0 0 0 3%;
                border-left: solid 0.12vw #aaa;
            }

            .category-container {
                display: grid;
                grid-template-columns: 27% 73%;
                align-items: center;
                text-transform: uppercase;

                .category-item-container {
                    display: flex;
                    flex-wrap: wrap;
                    
                    .category-item {
                        cursor: pointer;
                        transition: color 0.3s;
                        
                        &:hover {
                            color: #aaa;
                        }
                    }
                }
                
                
                h3 {
                    margin: 0.6vw 0;
                    font-size: 1.2vw;
                }
                p {
                    margin: 0 0 0 0.6vw;
                    font-size: 1.2vw;
                }
            }

            .share-container {
                display: grid;
                grid-template-columns: 27% 73%;
                align-items: center;

                svg {
                    height: 1.5vw;
                    width: 1.5vw;
                    margin: 0 0.3vw;
                    transition: fill 0.3s;

                    &:hover {
                        fill: #aaa;
                    }
                }

                h3 {
                    margin: 0.6vw 0;
                    font-size: 1.2vw;
                }

                .sm-container {
                    display: flex;
                }
            }
        }
    }

    .next-prev-container {
        display: grid;
        width: calc(100% - 30vw);
        padding: 3vw 15vw;
        grid-template-columns: 45% 6% 45%;
        grid-gap: 2%;
        margin: 6vw auto 0;
        background: rgba(255, 255, 255, 0.6);
        background: #fff;
        color: #000;
        font-size: 1.2vw;
        font-weight: 600;

        .next-btn {
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: linear 0.3s;
            
            &:hover {
                color: #818181;
            }

            svg {
                height: 2.1vw;
                width: 6vw;
                -moz-animation: bounceLeft 1.8s both linear infinite;
                -webkit-animation: bounceLeft 1.8s both linear infinite;
                animation: bounceLeft 1.8s both linear infinite;
            }

            p {
                margin: 0;
            }
        }

        .catalog-btn {
            text-align: center;
            cursor: pointer;

            svg {
                width: 2.1vw;
                height: 2.1vw;
            }
        }

        .prev-btn {
            text-align: right;
            display: flex;
            align-items: center;
            justify-self: flex-end;
            cursor: pointer;
            transition: linear 0.3s;
            
            &:hover {
                color: #818181;
            }

            svg {
                height: 2.1vw;
                width: 6vw;
                -moz-animation: bounceRight 1.8s both linear infinite;
                -webkit-animation: bounceRight 1.8s both linear infinite;
                animation: bounceRight 1.8s both linear infinite;
            }

            p {
                margin: 0;
            }
        }
    }
}

@-moz-keyframes bounceRight {
    0%, 100% {
        -webkit-transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(1.8vw);
    }
}
@-webkit-keyframes bounceRight {
    0%, 100% {
        -webkit-transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(1.8vw);
    }
}
@keyframes bounceRight {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(1.8vw);
    }
}

@-moz-keyframes bounceLeft {
    0%, 100% {
        -webkit-transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(-1.8vw);
    }
}
@-webkit-keyframes bounceLeft {
    0%, 100% {
        -webkit-transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(-1.8vw);
    }
}
@keyframes bounceLeft {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-1.8vw);
    }
}


@keyframes bounceDown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(1.8vw);
    }
}