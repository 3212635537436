.pagination-container {
    display: flex;
    justify-content: center;
    color: #fff;
    padding: 0 2.7vw;

    .prev-btn, .next-btn {
        width: 3vw;
        height: 1.5vw;
        text-align: center;
        border: #fff 0.12vw solid;
        padding: 0.3vw 0.12vw;
        cursor: pointer;
        transition: linear 0.12s;
        margin: 0 0.3vw;

        &:hover {
            background: #515151;
        }

        svg {
            width: 1.5vw;
            height: 1.5vw;
        }
    }

    .page-item {
        height: 2.1vw;
        width: 2.1vw;
        font-size: 1.2vw;
        line-height: 1.2vw;
        border: #fff 0.12vw solid;
        display: grid;
        place-content: center;
        margin: 0 0.3vw;
        cursor: pointer;
        transition: linear 0.12s;

        &.isSelected {
            background: #aaa;
        }

        &:hover {
            background: #515151;
        }
    }

    svg {
        fill: #fff;
    }
}

@media (max-width: 768px) {
    .pagination-container {
        margin: 6vw;
        .prev-btn, .next-btn {
            height: 6.9vw;
            width: 12vw;
            padding: 0;
            margin: 0 1.2vw;
            
            svg {
                width: 4.2vw;
                height: 6.9vw;
            }
        }

        .page-item {
            height: 6.9vw;
            width: 6.9vw;
            font-size: 3vw;
            margin: 0 1.2vw;
        }


    }
}